import { useCallback, useEffect, useRef, useState } from 'react'
import './Resizer.scss'

type Props = {
  minWidth: number
  setSplitRatio: (ratio: number) => void
  containerRef: React.RefObject<HTMLDivElement>
}

export default function Resizer({ minWidth, setSplitRatio, containerRef }: Props) {
  const [resizing, setResizing] = useState<boolean>(false)
  const handleRef = useRef<HTMLDivElement>(null)
  const handleStartResize = (event: MouseEvent) => {
    if (handleRef.current.contains(event.target as Node)) {
      setResizing(true)
      event.preventDefault()
    }
  }

  const handleEndResize = (event: MouseEvent) => {
    event.preventDefault()
    setResizing(false)
  }

  const handleResize = useCallback((event: MouseEvent) => {
    if (resizing) {
      const { clientX } = event
      const { clientWidth } = containerRef.current

      let finalX = clientX
      if (clientX < minWidth) {
        finalX = minWidth
        setResizing(false)
      } else if (clientX > clientWidth - minWidth) {
        finalX = clientWidth - minWidth
        setResizing(false)
      }
      const ratio = ((finalX - 40) / clientWidth) * 100
      setSplitRatio(ratio)
    }
  }, [resizing])

  const handleDoubleClick = (ratio: number) => {
    setSplitRatio(ratio)
  }

  useEffect(() => {
    if (handleRef.current) {
      document.addEventListener('mousedown', handleStartResize)
      document.addEventListener('mouseup', handleEndResize)
      document.addEventListener('mousemove', handleResize)
    }

    return () => {
      document.removeEventListener('mousedown', handleStartResize)
      document.removeEventListener('mousemove', handleResize)
      document.removeEventListener('mouseup', handleEndResize)
    }
  }, [handleRef.current, handleResize])

  return (
    <div id="handle" className="flex-center" onDoubleClick={() => handleDoubleClick(50)} ref={handleRef}>
      <div />
      <div />
    </div>
  )
}
