/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
import { useState } from 'react'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { LayerFeature } from 'types'
import { CHARTIS_SOURCES_IDS, CommentsSignal, getComments, postComment } from 'services'
import { Button, ButtonStyle, TextInput } from 'components'
import { useAppSelector, useMountEffect } from 'utils'
import { deleteFavoriteObject, FavoritesSignal, postFavoriteObject } from 'services/favorites'
import { PanelSignal } from '../PanelsManager'
import Comment from './Comment'

type Props = {
  selected: LayerFeature
  isFavorite: boolean
}

const TYPE_PROPERTIES = {
  [CHARTIS_SOURCES_IDS.signal]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.localisateur]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.passageNiveau]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.acces]: 'type_installation_fixe_id_libelle',
  [CHARTIS_SOURCES_IDS.dbc]: 'type_installation_fixe_id_mnemo',
  [CHARTIS_SOURCES_IDS.pedale]: 'type_installation_fixe_id_mnemo',
  [CHARTIS_SOURCES_IDS.sousStation]: 'type_electrifications_id_libelle',
  [CHARTIS_SOURCES_IDS.adv]: 'type_appareil_de_voie_id_mnemonique',
  [CHARTIS_SOURCES_IDS.advLine]: 'type_appareil_de_voie_id_mnemonique',
  [CHARTIS_SOURCES_IDS.poste]: 'type_installation_fixe_id_mnemo',
}

const getTypeProperty = (properties: {[key: string]: string}, source: string) => {
  if (!Object.keys(TYPE_PROPERTIES).includes(source)) return ''
  return Object.entries(properties).find(([key]) => key === TYPE_PROPERTIES[source])?.[1] || 'NC'
}

export default function ObjectSingle({ selected, isFavorite }: Props) {
  useMountEffect(() => getComments(selected.source, selected.properties?.id))
  const accountId = useAppSelector(({ user }) => user.account.id)
  const [newComment, setNewComment] = useState('')
  const [view, setView] = useState<'info' | 'comments'>('info')
  const [displayMenu, setDisplayMenu] = useState(false)
  const { properties, source } = selected
  const { lrs_ligne, lrs_voie, lrs_pk, libelle } = properties || {}
  const favoriteId = FavoritesSignal.objects.value.find(favorite => favorite.object_id === selected?.properties?.id)?.id
  PanelSignal.title.value = libelle || 'N/C'

  const handleSelectView = (v: 'info' | 'comments') => () => {
    setView(v)
    setDisplayMenu(false)
  }

  const handlePostFavorite = () => {
    postFavoriteObject({
      object_id: selected?.properties?.id,
      object_libelle: libelle || 'N/C',
      object_lvp: `${lrs_ligne} - ${lrs_voie || ''} ${lrs_pk || ''}`,
      layer_id_field: 'id',
      layer_slug: source,
    })
  }

  const handlePostComment = async () => {
    if (newComment) {
      postComment({
        user_id: accountId,
        content: newComment,
        type: 'Divers',
        object_id: selected?.properties?.id,
        object_libelle: libelle || 'N/C',
        layer_id_field: 'id',
        layer_slug: source,
      })
      setNewComment('')
    }
  }

  const renderInfo = () => (
    <div className="info">
      <p dangerouslySetInnerHTML={{ __html: `<b>Ligne : </b>${lrs_ligne}` }} />
      <p dangerouslySetInnerHTML={{ __html: `<b>Voie : </b>${lrs_voie}` }} />
      <p dangerouslySetInnerHTML={{ __html: `<b>PK : </b>${lrs_pk}` }} />
      {getTypeProperty(properties, source) && (
        <p>
          <b>Type : </b>
          {getTypeProperty(properties, source) || 'N/C'}
        </p>
      )}
    </div>
  )

  const renderComments = () => (
    <div className="comments">
      <div className="list hide-scroll">
        {CommentsSignal.value.map(comment => <Comment key={comment.id} comment={comment} />)}
      </div>
      <div className="add-comment">
        <TextInput label="Ajouter un commentaire" bindedValue={newComment} onChange={setNewComment} />
        <Button text="Publier" onClick={handlePostComment} />
      </div>
    </div>
  )

  const getTitle = () => {
    switch (view) {
      case 'info':
        return 'Infos'
      case 'comments':
        return `Commentaires (${CommentsSignal.value.length})`
      default:
        return 'Infos'
    }
  }

  const getView = () => {
    switch (view) {
      case 'info':
        return renderInfo()
      case 'comments':
        return renderComments()
      default:
        return renderInfo()
    }
  }

  return (
    <div className="object-single">
      <div className="divider" />
      <div className="header">
        <p>{getTitle()}</p>
        {isFavorite
          ? <FavoriteSelectedIcon onClick={() => deleteFavoriteObject(favoriteId)} />
          : <FavoriteIcon onClick={handlePostFavorite} />}
        <Button
          text=""
          icon={<BurgerIcon />}
          style={ButtonStyle.borderLess}
          onClick={() => setDisplayMenu(!displayMenu)}
        />
        <div className={`menu ${displayMenu ? 'display' : ''}`}>
          <Button
            text="Infos"
            style={ButtonStyle.borderLess}
            onClick={handleSelectView('info')}
            className={view === 'info' ? 'selected' : ''}
          />
          <Button
            text={`Commentaires (${CommentsSignal.value.length})`}
            style={ButtonStyle.borderLess}
            onClick={handleSelectView('comments')}
            className={view === 'comments' ? 'selected' : ''}
          />
        </div>
      </div>
      {selected && getView()}
    </div>
  )
}
