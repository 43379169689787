import { post } from '@osrdata/app_core/dist/requests'
import { FeatureOf, Polygon } from '@nebula.gl/edit-modes'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { Button, ButtonSize, ButtonStyle, ToastLevel, ToastSignal } from 'components'
import terms from 'assets/terms'
import { cancelPerimeterDraw } from 'services'
import { ModalSignal } from '../ModalWrapper'
import './SavePerimeterModal.scss'
import PerimeterFields from './PerimeterFields'

interface Props {
  perimeterGeometry: FeatureOf<Polygon>[]
}

export default function SavePerimeterModal({ perimeterGeometry }: Props) {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const handleSave = async () => {
    try {
      const newPerimeter = await post('/dexcarto/users/study-perimeters/', {
        type: 'Feature',
        properties: {
          name,
          description,
        },
        geometry: {
          type: 'Polygon',
          coordinates: perimeterGeometry[0].geometry.coordinates,
        },
      })
      ModalSignal.value = undefined
      navigate(`/perimetre/${newPerimeter.id}/?${params}`)
      cancelPerimeterDraw()
      ToastSignal.value = {
        severity: ToastLevel.SUCCESS,
        message: terms.Modals.SavePerimeter.success,
      }
    } catch (error) {
      ToastSignal.value = {
        severity: ToastLevel.ERROR,
        message: terms.Modals.SavePerimeter.error,
      }
    }
  }

  const handleClose = () => { ModalSignal.value = undefined }

  return (
    <div className="save-perimeter-modal">
      <div className="info flex-column-center">
        <h3>{terms.Modals.SavePerimeter.title}</h3>
        <span>{terms.Modals.SavePerimeter.info}</span>
      </div>
      <PerimeterFields name={name} setName={setName} description={description} setDescription={setDescription} />
      <div className="flex-center actions">
        <Button
          size={ButtonSize.medium}
          style={ButtonStyle.light}
          text={terms.Common.abort}
          onClick={handleClose}
          icon={<CloseIcon />}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.save}
          onClick={handleSave}
          icon={<SaveIcon />}
          disabled={!name}
          isAsync
        />
      </div>
    </div>
  )
}
