import { MapDataSignal } from 'pages/home/panels/PanelsManager'
import { CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'
import PointLayer from './generic/PointLayer'

export default function LocalisateurLayer({
  view, mapRef, visibility,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.localisateur,
    view,
    CHARTIS_SOURCES_IDS.localisateur,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PointLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.localisateur}
      layers={[
        MAPBOX_LAYER_IDS.localisateurCircle,
        MAPBOX_LAYER_IDS.localisateurHighlight,
        MAPBOX_LAYER_IDS.localisateurLabel,
      ]}
      color="#A51FFF"
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      textField={['format', 'Localisateur', '\n', ['get', 'libelle']]}
    />
  )
}
