import { signal } from '@preact/signals-react'
import { layerIndexes, layerMenu } from 'assets/layers'
import { MenuLayer } from 'types'

/**
 * Flatten the layer menu to get a list of slugs in a single depth object
 *
 * @param layers - Layer menu to flatten
 * @returns Flattened layer menu
 */
const flattenLayerMenu = (layers = layerMenu) => (
  // eslint-disable-next-line consistent-return, array-callback-return
  Object.entries(layers).reduce((acc, [key, value]) => {
    if (key === 'type' || key === 'tooltip') return acc
    if (typeof value === 'string') return { ...acc, [key]: value }
    if (typeof value === 'object') return { ...acc, ...flattenLayerMenu(value) }
  }, {})
)

const getSlugsWithoutRadios = (layers: MenuLayer) => {
  // eslint-disable-next-line consistent-return, array-callback-return
  const labels = Object.entries(layers).reduce((acc, [key, value]) => {
    if (key === 'type' || key === 'tooltip') return acc
    if (typeof value === 'string') return [...acc, key]
    if (typeof value === 'object' && value?.type === 'radio') return acc
    if (typeof value === 'object') return [...acc, key, ...getSlugsWithoutRadios(value)]
  }, [])
  return labels
}

/**
 * Signal to store the selected layers and update url layer params
 */
export const LayersSignal = signal<string[]>([])

/**
 * Get index key for the given slug
 *
 * Example : getIndexForSlug('zoneActionPoste') => '1.1'
 * @param value - Value to get index from
 * @returns Index key
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getIndexForSlug = (slug: string) => Object.entries(layerIndexes).find(([_, val]) => val === slug)?.[0]

/**
 * Get slugs list for given indexes
 *
 * Mainly used to display selected layers in the layer panel or to update the LayersSignal
 *
 * Example : getSlugsForIndexes(['1.1', '1.2', '1.3']) => ['zoneActionPoste', 'zoneActionPosteAmendee', 'bal-zone']
 * @param indexes - List of indexes
 * @returns List of slugs
 */
export const getSlugsForIndexes = (indexes: string[]) => indexes?.map(index => layerIndexes[index])

/**
 * Get labels for given indexes
 *
 * @param indexes - List of indexes
 * @returns List of labels
 */
export const getLabelsForIndexes = (indexes: string[]) => (
  getSlugsForIndexes(indexes)?.map(index => flattenLayerMenu()[index]).filter(Boolean)
)

/**
 * Get parent index for the given index
 *
 * Example : getParentForIndex('1.1') => '1'
 *
 * @param index - Index to get parent from
 * @returns Parent index
 */
export const getParentForIndex = (index: string) => index.split('.').slice(0, -1).join('.')

/**
 * Get all indexes that are children of the given index
 *
 * Example : getChildrenForIndex('1') => ['1.1', '1.2', '1.3', '1.4', '1.5']
 * @param index - Index to get children from
 * @returns List of children indexes
 */
export const getChildrenForIndex = (index: string) => (
  Object.entries(layerIndexes).filter(([key]) => key.startsWith(`${index}.`)).map(([key]) => key)
)

/**
 * Get all parents for the given index (recursive)
 *
 * Example : getAllAncestors(2.2.4) => ['2.2', '2']
 * @returns List of parent indexes
 */
export const getAllAncestors = (index: string): string[] => {
  // Split the index by '.' to traverse the hierarchy
  const parts = index.split('.')
  if (parts.length <= 1) {
    return [] // Base case: no more parents, this is the root
  }

  // Remove the last part to get the parent index
  parts.pop()
  const parentIndex = parts.join('.')

  // Recursive call to get ancestors of the current parent
  return [parentIndex, ...getAllAncestors(parentIndex)]
}

/**
 * Get all indexes that are siblings of the given index but its children
 *
 * Example : getIndexesSiblingsForIndex('1.1') => ['1.2', '1.3', '1.4', '1.5']
 * @param index - Index to get siblings from
 * @returns List of siblings indexes
 */
export const getIndexesSiblingsForIndex = (index: string) => {
  const parent = getParentForIndex(index)
  return Object.entries(layerIndexes)
    .filter(([key]) => key.startsWith(parent) && !key.startsWith(index))
    .map(([key]) => key)
}

/**
 * Check if all indexes are selected
 * @returns True if all indexes are selected, false otherwise
 */
export const allIndexesSelected = () => {
  const slugs = getSlugsWithoutRadios(layerMenu).map(slug => getIndexForSlug(slug))
  return slugs.every(index => LayersSignal.value.includes(index))
}

/**
 * Toggle all indexes selection
 */
export const toggleAllIndexes = () => {
  const slugs = getSlugsWithoutRadios(layerMenu).map(slug => getIndexForSlug(slug))
  LayersSignal.value = allIndexesSelected() ? [] : slugs
}

/**
 * Check if the given index is selected
 * @param slug - Slug to check
 * @returns True if the slug is selected, false otherwise
 */
export const isLayerSelected = (slug: string) => LayersSignal.value.includes(getIndexForSlug(slug))

/**
 * Check if some children of the given index are selected
 * @param slug - Slug to check
 * @returns True if some children are selected, false otherwise
 */
export const isSomeChildSelected = (slug: string) => {
  const index = getIndexForSlug(slug)
  const children = getChildrenForIndex(index)
  return children.some(child => LayersSignal.value.includes(child))
}

export const handleLayerMenuSelect = (slug: string, type = 'checkbox') => {
  const { value } = LayersSignal

  const index = getIndexForSlug(slug)
  const children = getChildrenForIndex(index)
  const childrenWithoutRadios = children.filter(
    child => getSlugsWithoutRadios(layerMenu).map(s => getIndexForSlug(s)).includes(child),
  )
  const siblings = getIndexesSiblingsForIndex(index)
  const ancestors = getAllAncestors(index)

  if (value.includes(index)) {
    // If the index is already selected, remove it and its children/parents

    if (type === 'radio' || (
      type === 'radio-child' && siblings.some(s => value.includes(s) && !ancestors.includes(s))
    )) {
      LayersSignal.value = value.filter(
        i => i !== index && !children.includes(i),
      )
    } else {
      LayersSignal.value = value.filter(
        i => i !== index && !children.includes(i) && !ancestors.includes(i),
      )
    }
  } else {
    // Determine which ancestors should be checked
    const shouldCheckAncestors = ancestors.map(parent => getChildrenForIndex(parent).every(
      i => [...LayersSignal.value, ...children, index, ...ancestors].includes(i),
    ) && parent).filter(Boolean)

    LayersSignal.value = Array.from(new Set([
      ...value,
      ...shouldCheckAncestors,
      ...(type !== 'radio' ? childrenWithoutRadios : []),
      ...(type === 'radio' ? children : []),
      ...(type === 'radio-child' ? ancestors : []),
      index,
    ])).filter(
      i => (type === 'radio' ? !siblings.includes(i) : true),
    ).filter(Boolean)
  }
}
