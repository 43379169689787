import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import schImage from 'assets/icons/sch-map.png'
import geoImage from 'assets/icons/geo-map.png'
import terms from 'assets/terms'
import { reprojectViewport } from 'services'
import ToolWrapper from './ToolWrapper'
import { ContentSignals } from '../contents/ContentsManager'

import './MapSelectionTool.scss'

export default function MapSelectionTool() {
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  /** Set url params based on selected map (params updates are catched in ContentsManager) */
  const handleMapSelection = (map: 'sch' | 'geo' | 'truncate') => async () => {
    if (map === 'sch' || map === 'geo') {
      const newVp = await reprojectViewport(leftData.value?.vp, leftData.value.type, map)
      leftData.value = { ...leftData.value, type: map, vp: newVp }
      ContentSignals.right.data.value = null
    } else {
      const rightType = leftData.value?.type === 'sch' ? 'geo' : 'sch'
      const rightVp = await reprojectViewport(leftData.value?.vp, leftData.value?.type, rightType)
      rightData.value = { type: rightType, vp: rightVp }
    }
  }

  return (
    <ToolWrapper title={terms.ToolsPanel.maps.title}>
      <div className="maps-selection-wrapper">
        <button type="button" className="map-button" onClick={handleMapSelection('sch')}>
          <img src={schImage} alt="sch-view" />
          {terms.ToolsPanel.maps.sch}
        </button>
        <button type="button" className="map-button" onClick={handleMapSelection('geo')}>
          <img src={geoImage} alt="geo-view" />
          {terms.ToolsPanel.maps.geo}
        </button>
        <button type="button" className="map-button truncate" onClick={handleMapSelection('truncate')}>
          <div>
            <img src={geoImage} alt="truncate-view-1" />
            <img src={schImage} alt="truncate-view-2" />
            <div className="shadow" />
            <PlusIcon />
          </div>
          {terms.ToolsPanel.maps.truncate}
        </button>
      </div>
    </ToolWrapper>
  )
}
