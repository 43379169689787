import { Layer, Source } from 'react-map-gl'
import { CHARTIS_SOURCES_IDS, isLayerSelected, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'

/*
valeur,valeur_libelle
45bd684e-6665-11e3-afff-01f464e0362d,Installations permanentes de contre sens
45bf8bce-6665-11e3-afff-01f464e0362d,Voie banalisée
45bd6dac-6665-11e3-afff-01f464e0362d,Voie banalisée
45bf7922-6665-11e3-afff-01f464e0362d,Voie de Service
e0c0421a-19b6-11e5-80ff-01fc64e0362d,Voie de Service
45c0be0c-6665-11e3-afff-01f464e0362d,voie non Exploitée
45c26bc4-6665-11e3-afff-01f464e0362d,Voie unique
45c48a46-6665-11e3-afff-01f464e0362d,Voie unique à signalisation simplifiée
45bd7314-6665-11e3-afff-01f464e0362d,Voie unique à trafic restreint (consigne de ligne)
45bbeebe-6665-11e3-afff-01f464e0362d,Voie unique temporaire à caractère permanent
*/

const EM_UNIQUE_IDS = ['45c26bc4-6665-11e3-afff-01f464e0362d', '45c48a46-6665-11e3-afff-01f464e0362d',
  '45bd7314-6665-11e3-afff-01f464e0362d', '45bbeebe-6665-11e3-afff-01f464e0362d']

const EM_BANALISEE_IDS = ['45bf8bce-6665-11e3-afff-01f464e0362d', '45bd6dac-6665-11e3-afff-01f464e0362d']

const EM_IPCS_IDS = ['45bd684e-6665-11e3-afff-01f464e0362d']

const EXPLOITATION_MODES = [...EM_UNIQUE_IDS, ...EM_BANALISEE_IDS, ...EM_IPCS_IDS]

function ExploitationModeLayer({ mapRef, view, visibility }: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.exploitationMode,
    view,
    CHARTIS_SOURCES_IDS.exploitationMode,
    mapRef,
    { ...perimeterParam, valeur__in: EXPLOITATION_MODES.join(',') },
  )

  return (
    <Source
      id={CHARTIS_SOURCES_IDS.exploitationMode}
      type="vector"
      url={url}
    >
      <Layer
        id={MAPBOX_LAYER_IDS.exploitationMode}
        type="symbol"
        source-layer={CHARTIS_SOURCES_IDS.exploitationMode}
        paint={{}}
        layout={{
          'icon-image': ['case',
            ['in', ['get', 'valeur'], ['literal', EM_UNIQUE_IDS]], 'voie_unique_no_line',
            ['in', ['get', 'valeur'], ['literal', EM_BANALISEE_IDS]], 'voie_banalisee_no_line',
            ['in', ['get', 'valeur'], ['literal', EM_IPCS_IDS]], 'ipcs_no_line',
            ''],
          'icon-size': 0.8,
          'symbol-placement': 'line-center',
          'icon-allow-overlap': true,
          'symbol-spacing': 500,
          visibility: visibility ? 'visible' : 'none',
        }}
        filter={['in', ['get', 'valeur'], ['literal', [
          ...(isLayerSelected('exploitation-mode-unique') ? [...EM_UNIQUE_IDS] : []),
          ...(isLayerSelected('exploitation-mode-banalisee') ? [...EM_BANALISEE_IDS] : []),
          ...(isLayerSelected('exploitation-mode-ipcs') ? [...EM_IPCS_IDS] : []),
        ]]]}
        minzoom={12}
      />
    </Source>
  )
}

export default ExploitationModeLayer
