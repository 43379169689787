import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { ModalSignal } from 'components'
import { HomePage } from 'pages'

export default function Router() {
  const location = useLocation()

  useEffect(() => {
    // reset root signals when changing route
    ModalSignal.value = undefined
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<HomePage />} path="/perimetre/:perimeterId/" />
      <Route element={<HomePage />} path="/" />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
