import { get } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import { Views } from 'types'

export const ViewSignal = signal<Views[]>([])
export const getViews = async () => {
  ViewSignal.value = await get<Views[]>(
    '/dexcarto/users/views',
    { ordering: '-creation_date' },
  )
}
