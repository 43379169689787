import { CHARTIS_SOURCES_IDS } from 'services'
import { LayerFeature } from 'types'
import { MapDataSignal, PanelSignal } from '../PanelsManager'

type Props = {
  data: LayerFeature[]
  onSelect: (d: LayerFeature) => void
}

const SOURCE_NAMES = {
  [CHARTIS_SOURCES_IDS.signal]: 'Signalisation',
  [CHARTIS_SOURCES_IDS.localisateur]: 'Localisateur',
  [CHARTIS_SOURCES_IDS.passageNiveau]: 'Passage à niveau',
  [CHARTIS_SOURCES_IDS.acces]: 'Accès',
  [CHARTIS_SOURCES_IDS.dbc]: 'Détecteur de boîte chaude',
  [CHARTIS_SOURCES_IDS.pedale]: 'Pédale',
  [CHARTIS_SOURCES_IDS.sousStation]: 'Sous-station',
  [CHARTIS_SOURCES_IDS.adv]: 'Appareil de voie',
  [CHARTIS_SOURCES_IDS.station]: 'Gare',
  [CHARTIS_SOURCES_IDS.jdz]: 'Joint de zone',
  [CHARTIS_SOURCES_IDS.cdv]: 'Circuit de voie',
  [CHARTIS_SOURCES_IDS.rac]: 'Ligne',
  [CHARTIS_SOURCES_IDS.bif]: 'Point remarquable',
  [CHARTIS_SOURCES_IDS.poste]: 'Poste',
  [CHARTIS_SOURCES_IDS.chantier]: 'Chantier',
  [CHARTIS_SOURCES_IDS.zap]: 'Zone d\'action de poste',
  [CHARTIS_SOURCES_IDS.bal]: 'Zone de BAL',
  [CHARTIS_SOURCES_IDS.ihm]: 'Zone IHM',
}

export default function ObjectList({ data, onSelect }: Props) {
  PanelSignal.title.value = `Objets superposés (${data?.length})`
  const sources = data?.map(d => d.source).filter(
    (value, index, self) => self.indexOf(value) === index && Object.keys(SOURCE_NAMES).includes(value),
  )

  const handleSelect = (feature: LayerFeature) => () => { onSelect(feature) }
  const handleHover = (features: LayerFeature[]) => () => { MapDataSignal.hoveredObjects.value = features }

  return (
    <div className="objects-list">
      {sources?.map(source => (
        <div key={source}>
          <p className="source">{SOURCE_NAMES[source] ?? source}</p>
          {data?.filter(d => d.source.includes(source)).map(d => (
            <div
              key={d.properties.id}
              className="data"
              onClick={handleSelect(d)}
              onMouseEnter={handleHover([d])}
              onMouseLeave={handleHover([])}
            >
              <p>{d.properties.libelle || 'N/C'}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
