import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { Button, ButtonStyle, ModalSignal } from 'components'
import { Perimeter } from 'types'
import { useRef, useState } from 'react'
import { useClickOutside } from 'utils'
import { startPatchPerimeterGeometry, startPatchPerimeterProps } from 'services'
import PatchPerimeterModal from 'components/modals/perimeter/PatchPerimeterModal'
import InfosTab from './tabs/InfosTab'
import { PanelSignal } from '../PanelsManager'
import PerimetersPanel from '../perimeters/PerimetersPanel'
import './PerimeterInfoPanel.scss'

export default function PerimeterInfoPanel({ perimeter }: {perimeter: Perimeter}) {
  const menuWrapperRef = useRef<HTMLDivElement>(null)
  const [menuDisplayed, setMenuDisplayed] = useState(false)

  const closeMenu = () => setMenuDisplayed(false)
  const toggleMenu = () => setMenuDisplayed(!menuDisplayed)

  useClickOutside(menuWrapperRef, closeMenu)

  const handleFavorite = () => {
    /* to implement */
  }

  const handleEditGeometry = () => {
    startPatchPerimeterGeometry(perimeter)
  }

  const handleEditProperties = () => {
    startPatchPerimeterProps(perimeter)
    ModalSignal.value = (<PatchPerimeterModal perimeter={perimeter} />)
  }

  return (
    <div className="perimeter-info-panel panel">
      <Button
        className="button-back"
        style={ButtonStyle.borderLess}
        text=""
        icon={<ArrowLeftIcon />}
        onClick={() => {
          PanelSignal.title.value = 'Périmètres'
          PanelSignal.content.value = <PerimetersPanel />
        }}

      />
      <div className="header">
        <h3>{`Périmètre ${perimeter.properties.name}`}</h3>
        <div className="actions">
          <div className="settings-menu" ref={menuWrapperRef}>
            <Button
              className="settings-button"
              style={ButtonStyle.borderLess}
              text=""
              icon={<SettingsIcon />}
              onClick={toggleMenu}
            />
            <ul className={menuDisplayed ? 'displayed' : ''}>
              <li>
                <a onClick={handleEditGeometry} className="flex-center">
                  Modifier la géométrie
                </a>
              </li>
              <li>
                <a onClick={handleEditProperties} className="flex-center">
                  Modifier les propriétés
                </a>
              </li>
            </ul>
          </div>
          <Button
            style={ButtonStyle.borderLess}
            text=""
            icon={<FavoriteIcon fill="red" />}
            disabled
            onClick={handleFavorite}
          />
        </div>
      </div>
      <div className="divider" />
      <div className="content">
        <InfosTab perimeter={perimeter} />
      </div>
    </div>
  )
}
