import { signal } from '@preact/signals-react'
import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { CommentType } from 'types'
import { catchable } from 'utils'
import { ToastLevel, ToastSignal } from 'components'

export const CommentsSignal = signal<CommentType[]>([])

export const getComments = async (layer: string, id: string) => {
  CommentsSignal.value = []
  const [error, response] = await catchable<CommentType[]>(() => get('/dexcarto/users/object-comments/', {
    object_id: id,
    layer_slug: layer,
  }), true)
  if (error) return
  CommentsSignal.value = response
}

export const postComment = async (comment: Omit<CommentType, 'id'>) => {
  const [error] = await catchable(() => post('/dexcarto/users/object-comments/', comment), true)
  if (error) return
  getComments(comment.layer_slug, comment.object_id)
}

export const deleteComment = async (id: string) => {
  const [error] = await catchable(() => deleteRequest(`/dexcarto/users/object-comments/${id}`), true)
  if (error) return
  ToastSignal.value = {
    severity: ToastLevel.INFO,
    message: 'Votre commentaire a été supprimé',
  }
  CommentsSignal.value = CommentsSignal.value.filter(comment => comment.id !== id)
}
