import { signal } from '@preact/signals-react'
import { get } from '@osrdata/app_core/dist/requests'
import { Collection, PaginatedResponse } from 'types'
import { catchable } from 'utils'

export const CollectionSignal = {
  lists: signal<Collection[]>([]),
  loading: signal<boolean>(false),
  next: null,
}

export const getCollections = async (search?: string) => {
  CollectionSignal.lists.value = []
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(
    () => get<PaginatedResponse<Collection>>('/dexcarto/ouranos/collections/', search && {
      libelle_is: search,
    }),
  )
  if (!error) {
    CollectionSignal.lists.value = response.results
    CollectionSignal.next = response.next
  }
  CollectionSignal.loading.value = false
}

export const getNextCollections = async () => {
  if (!CollectionSignal.next) return
  CollectionSignal.loading.value = true
  const [error, response] = await catchable(() => get<PaginatedResponse<Collection>>(CollectionSignal.next))
  if (!error) {
    CollectionSignal.lists.value = [...CollectionSignal.lists.value, ...response.results]
    CollectionSignal.next = response.next
  }
  CollectionSignal.loading.value = false
}
