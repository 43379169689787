/* eslint-disable no-console */
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite-full.svg'
import { ReactComponent as FavoriteSelectedIcon } from 'assets/icons/favorite-selected.svg'
import { ReactComponent as PerimeterIcon } from 'assets/icons/perimeter.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg'
import terms from 'assets/terms'
import { Button, ButtonStyle, ButtonSize } from 'components'
import ToolWrapper from './ToolWrapper'
import { PanelSignal } from '../panels/PanelsManager'
import ViewPanel from '../panels/views/ViewPanel'
import PerimetersPanel from '../panels/perimeters/PerimetersPanel'
import FavoritesPanel from '../panels/favorites/FavoritesPanel'

export default function AgentTool() {
  const agent = [
    {
      label: terms.ToolsPanel.agent.favorites,
      icon: <FavoriteIcon />,
      onClick: () => {
        PanelSignal.title.value = (
          <>
            <FavoriteSelectedIcon />
            Favoris
          </>
        )
        PanelSignal.content.value = <FavoritesPanel />
      },
      enable: true,
    },
    {
      label: terms.ToolsPanel.agent.perimeters,
      icon: <PerimeterIcon />,
      onClick: () => {
        PanelSignal.title.value = 'Périmètres'
        PanelSignal.content.value = <PerimetersPanel />
      },
      enable: true,
    },
    {
      label: terms.ToolsPanel.agent.views,
      icon: <GridIcon />,
      onClick: () => {
        PanelSignal.title.value = 'Vues'
        PanelSignal.content.value = <ViewPanel />
      },
      enable: true,
    },
    {
      label: terms.ToolsPanel.agent.contributes,
      icon: <MessageIcon />,
      onClick: () => console.log('contributes'),
      enable: true,
    },
  ]

  return (
    <ToolWrapper title={terms.ToolsPanel.agent.title} animate>
      {agent.map(tool => (
        <Button
          key={tool.label}
          style={ButtonStyle.borderLess}
          text={tool.label}
          size={ButtonSize.medium}
          icon={tool.icon}
          onClick={tool.onClick}
          disabled={!tool.enable}
        />
      ))}
    </ToolWrapper>
  )
}
