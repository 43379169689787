import { useState } from 'react'
import terms from 'assets/terms'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { Button, ButtonSize, ButtonStyle } from 'components'
import { handlePatchProperties } from 'services'
import { Perimeter } from 'types'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import PerimeterInfoPanel from 'pages/home/panels/perimeterInfo/PerimeterInfoPanel'
import { ModalSignal } from '../ModalWrapper'
import PerimeterFields from './PerimeterFields'
import './PatchPerimeterModal.scss'

export default function PatchPerimeterModal({ perimeter }: {perimeter: Perimeter}) {
  const [name, setName] = useState(perimeter?.properties?.name || '')
  const [description, setDescription] = useState(perimeter?.properties?.description || '')

  const handleClose = () => { ModalSignal.value = undefined }

  const handleSave = async () => {
    const [error, response] = await handlePatchProperties(name, description)
    if (!error) {
      handleClose()
      PanelSignal.content.value = <PerimeterInfoPanel perimeter={response} />
    }
  }
  return (
    <div className="patch-perimeter-modal">
      <div className="info flex-column-center">
        <h3>{terms.Modals.PatchPerimeter.title}</h3>
        <span>{terms.Modals.PatchPerimeter.info}</span>
      </div>
      <PerimeterFields name={name} setName={setName} description={description} setDescription={setDescription} />
      <div className="flex-center actions">
        <Button
          size={ButtonSize.medium}
          style={ButtonStyle.light}
          text={terms.Common.abort}
          onClick={handleClose}
          icon={<CloseIcon />}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.save}
          onClick={handleSave}
          icon={<SaveIcon />}
          disabled={!name}
          isAsync
        />
      </div>
    </div>
  )
}
