import { useEffect, useState } from 'react'
import { LayerFeature } from 'types'
import { FavoritesSignal } from 'services/favorites'
import { MapDataSignal } from '../PanelsManager'
import ObjectList from './ObjectList'
import ObjectSingle from './ObjectSingle'

import './InfoPanel.scss'

export default function InfoPanel() {
  const [data, setData] = useState<LayerFeature[]>()
  const [selected, setSelected] = useState<LayerFeature>()
  const features = MapDataSignal.clickedObjects.value || []
  const favorites = FavoritesSignal.objects.value

  useEffect(() => {
    // remove duplicates (same properties.id)
    const filtered = features.filter(
      (feature, index, arr) => arr.findIndex(f => f.properties.id === feature.properties.id) === index,
    )

    if (filtered.length === 1) {
      setSelected(filtered[0])
      setData([])
    } else {
      setSelected(null)
      setData(filtered)
    }
  }, [features])

  return (
    <div className="info-panel panel">
      {!selected
        ? <ObjectList data={data} onSelect={setSelected} />
        : (
          <ObjectSingle
            selected={selected}
            isFavorite={!!favorites.find(
              favorite => favorite.object_id === selected?.properties?.id,
            )}
          />
        )}
    </div>
  )
}
