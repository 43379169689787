import { Layer, Source } from 'react-map-gl'
import { hoverColor } from 'utils'

interface Props {
  url: string
  sourceId: string
  layers: [string, string]
  hoveredObjectsIds: string[]
  opacity: number
  lineFilter: string
  visibility: boolean
  color: string
}

/**
 * Generic layer displaying a line and (eventually) a label
 * It handles hovered object text highlighting and opacity decrease when filtering on a line
 * Renders a line and a label mapbox layers
 * @param {string} url - The url of the vector source
 * @param {string} sourceId - The id of the source
 * @param {string[]} layers - The ids of the layers
 * @param {string[]} hoveredObjectsIds - The ids of the hovered objects
 * @param {number} opacity - The opacity of the layer
 * @param {string} lineFilter - The line filter
 * @param {boolean} visibility - The visibility of the layer
 * @param {string} color - The color of the line
 * @return {JSX.Element} The line layer
 */
export default function LineLayer({
  url, sourceId, layers, hoveredObjectsIds, opacity, lineFilter, visibility, color,
}: Props) {
  const [lineLayer, labelLayer] = layers
  return (
    <Source
      id={sourceId}
      type="vector"
      url={url}
    >
      <Layer
        type="line"
        paint={{
          'line-width': 3,
          'line-color': hoverColor(color, hoveredObjectsIds),
          'line-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
        }}
        id={lineLayer}
        source-layer={sourceId}
        layout={{ visibility: visibility ? 'visible' : 'none' }}
      />
      <Layer
        type="symbol"
        id={labelLayer}
        source-layer={sourceId}
        paint={{
          'text-color': '#ffffff',
          'text-halo-color': hoverColor(color, hoveredObjectsIds),
          'text-halo-width': 1.5,
        }}
        layout={{
          'text-font': ['Open Sans Regular'],
          'symbol-placement': 'line',
          'symbol-spacing': 500,
          'text-size': 14,
          'text-allow-overlap': false,
          'text-field': '{libelle}',
          visibility: visibility ? 'visible' : 'none',
        }}
        minzoom={12}
      />
    </Source>
  )
}
