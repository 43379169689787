import { deleteRequest } from '@osrdata/app_core/dist/requests'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import terms from 'assets/terms'
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { shouldBreakWord, useMountEffect } from 'utils'
import { Perimeter } from 'types'
import { getPerimeters, PerimeterSignals, updatePerimeterViewport } from 'services'
import {
  Button, ButtonStyle, ConfirmModal, ModalSignal, TextInput, ToastLevel, ToastSignal,
} from 'components'

import PerimeterInfoPanel from '../perimeterInfo/PerimeterInfoPanel'
import { PanelSignal } from '../PanelsManager'
import './PerimetersPanel.scss'

export default function PerimetersPanel() {
  useMountEffect(() => { getPerimeters() })
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [filter, setFilter] = useState('')
  const [toogledContents, setToogledContents] = useState<number[]>([])
  const filteredPerimeters = PerimeterSignals.list.value?.filter(
    perimeter => perimeter.properties.name.toLowerCase().includes(filter),
  )

  const handleSortPerimeters = (value: string) => { setFilter(value.toLowerCase()) }

  const handleLoadPerimeter = (perimeter: Perimeter) => async () => {
    navigate(`/perimetre/${perimeter.id}/?${params}`)
    updatePerimeterViewport(perimeter)
    PanelSignal.title.value = ''
    PanelSignal.content.value = <PerimeterInfoPanel perimeter={perimeter} />
  }

  const handleToggleContent = (id: number) => () => {
    if (toogledContents.includes(id)) {
      setToogledContents(toogledContents.filter(content => content !== id))
    } else {
      setToogledContents([...toogledContents, id])
    }
  }

  const handleFavorite = () => () => {
    /* to implement */
  }

  const handleDelete = (id: number) => () => {
    ModalSignal.value = (
      <ConfirmModal
        title={terms.Panels.Perimeters.deleteConfirm}
        handleClose={() => { ModalSignal.value = null }}
        handleValidate={() => {
          deleteRequest(`/dexcarto/users/study-perimeters/${id}/`)
            .then(() => {
              if (PerimeterSignals.activePerimeter.value?.id === id) {
                PerimeterSignals.activePerimeter.value = null
                navigate('/')
              }
              PerimeterSignals.list.value = PerimeterSignals.list.value?.filter(perimeter => perimeter.id !== id)
              ModalSignal.value = null
              ToastSignal.value = {
                severity: ToastLevel.SUCCESS,
                message: terms.Panels.Perimeters.deleteSuccess,
              }
            }).catch(() => {
              ToastSignal.value = {
                severity: ToastLevel.ERROR,
                message: terms.Panels.Perimeters.deleteError,
              }
            })
        }}
      />
    )
  }

  return (
    <div className="perimeters-panel">
      <TextInput label={terms.Common.search} onChange={handleSortPerimeters} />
      <table className="perimeters">
        <thead>
          <tr>
            <th><div className="name">{terms.Panels.Perimeters.name}</div></th>
            <th><div className="description">{terms.Panels.Perimeters.description}</div></th>
            <th><div className="date">{terms.Panels.Perimeters.creationDate}</div></th>
            <th><div className="access">{terms.Panels.Perimeters.access}</div></th>
            <th><div className="favorite">{terms.Panels.Perimeters.actions}</div></th>
          </tr>
        </thead>
        <tbody>
          {filteredPerimeters?.map(perimeter => (
            <tr className="item" key={perimeter.id}>
              <td>
                <div className="name">
                  {perimeter.properties.name}
                </div>
              </td>
              <td>
                <div
                  className={`description${shouldBreakWord(perimeter.properties.description) ? ' break-word' : ''}`}
                >
                  <span className={`${toogledContents.includes(perimeter.id) ? 'expanded' : ''}`}>
                    {perimeter.properties.description}
                  </span>
                  <Button
                    style={ButtonStyle.borderLess}
                    text={`${toogledContents.includes(perimeter.id) ? terms.Common.showLess : terms.Common.showMore}`}
                    onClick={handleToggleContent(perimeter.id)}
                  />
                </div>
              </td>
              <td><div className="date">{moment(perimeter.properties.creation_date).format('DD/MM/YYYY')}</div></td>
              <td>
                <div className="access flex-center">
                  <Button text="" icon={<ArrowRightIcon />} onClick={handleLoadPerimeter(perimeter)} isAsync />
                </div>
              </td>
              <td>
                <div className="actions flex-center">
                  <Button
                    style={ButtonStyle.borderLess}
                    text=""
                    icon={<FavoriteIcon />}
                    disabled
                    onClick={handleFavorite()}
                    isAsync
                  />
                  <Button
                    style={ButtonStyle.borderLess}
                    text=""
                    icon={<DeleteIcon />}
                    onClick={handleDelete(perimeter.id)}
                    isAsync
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
