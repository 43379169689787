/* eslint-disable no-console */
import terms from 'assets/terms'
import { Button, ButtonStyle, ButtonSize, ModalSignal, ReportModal } from 'components'
import ToolWrapper from './ToolWrapper'

export default function ContributeTool() {
  const contribute = [
    {
      label: terms.ToolsPanel.contribute.suggest,
      onClick: () => {
        ModalSignal.value = <ReportModal type="Suggestion d'édition" />
      },
      enable: true,
    },
    {
      label: terms.ToolsPanel.contribute.comments,
      onClick: () => console.log('comments'),
      enable: true,
    },
  ]

  return (
    <ToolWrapper title={terms.ToolsPanel.contribute.title}>
      {contribute.map(tool => (
        <Button
          key={tool.label}
          style={ButtonStyle.borderLess}
          text={tool.label}
          size={ButtonSize.medium}
          onClick={tool.onClick}
          disabled={!tool.enable}
        />
      ))}
    </ToolWrapper>
  )
}
