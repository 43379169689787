import { signal, Signal } from '@preact/signals-core'
import { geoMapStyle, schMapStyle } from 'assets/map'
import { useEffect, useMemo, useRef } from 'react'
import ReactMapGL, { MapEvent, MapRef, ViewportProps } from 'react-map-gl'
import { INTERACTIVE_LAYER_IDS, loadMissingImage } from 'services'
import { transformRequest } from 'utils'
import { Loader } from 'components'
import { ContentType } from 'types'
import { MapDataSignal, PanelSignal } from 'pages/home/panels/PanelsManager'
import InfoPanel from 'pages/home/panels/info/InfoPanel'
import GeoLayers from './GeoLayers'
import SchLayers from './SchLayers'
import MapControls from './controls/MapControls'

type MapProps = {
  data: Signal<Omit<ContentType, 'id'>>
}

export default function Map({ data }: MapProps) {
  const LoadingSignal = useMemo(() => signal<boolean>(true), [])
  const mapRef = useRef<MapRef>()
  const { type, vp } = data.value

  const onViewportChange = (newViewport: ViewportProps) => {
    const newVp = { ...newViewport, transitionDuration: 0 }
    data.value = { type, vp: newVp }
  }

  const handleClickFeatures = (e: MapEvent) => {
    if (e.features?.length > 0) {
      MapDataSignal.clickedObjects.value = e.features
      PanelSignal.content.value = <InfoPanel />
    }
  }

  // Display loader when map is loading data
  useEffect(() => {
    if (!mapRef.current) return undefined
    const startLoading = () => { LoadingSignal.value = true }
    const stopLoading = () => { LoadingSignal.value = false }

    mapRef.current.getMap().on('idle', stopLoading)
    mapRef.current.getMap().on('sourcedataloading', startLoading)

    return () => {
      stopLoading()
      mapRef.current?.getMap().off('idle', stopLoading)
      mapRef.current?.getMap().off('sourcedataloading', startLoading)
    }
  }, [type])

  useEffect(() => {
    const map = mapRef?.current?.getMap()
    const onStyleImageMissing = (e: {id: string}) => { loadMissingImage(mapRef, e.id) }
    map.on('styleimagemissing', onStyleImageMissing)
    return () => { map.off('styleimagemissing', onStyleImageMissing) }
  }, [])

  useEffect(() => {
    const map = mapRef?.current?.getMap()
    const style = type === 'geo' ? geoMapStyle : schMapStyle
    map.setStyle(style)
  }, [type])

  return (
    <>
      {LoadingSignal.value && <Loader variant="small" />}
      <ReactMapGL
        {...vp}
        width="100%"
        height="100%"
        onViewportChange={onViewportChange}
        clickRadius={2}
        transformRequest={transformRequest}
        interactiveLayerIds={INTERACTIVE_LAYER_IDS}
        ref={mapRef}
        mapStyle={schMapStyle}
        onHover={e => { MapDataSignal.hoveredObjects.value = e.features }}
        onClick={handleClickFeatures}
      >
        {type === 'geo' ? <GeoLayers mapRef={mapRef} /> : <SchLayers mapRef={mapRef} />}
        <MapControls data={data} />
      </ReactMapGL>
    </>
  )
}
