/* eslint-disable react/no-danger */
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import terms from 'assets/terms'
import { Button, ButtonSize, ButtonStyle, ModalSignal } from 'components'
import { PanelSignal } from 'pages/home/panels/PanelsManager'
import ViewPanel from 'pages/home/panels/views/ViewPanel'

import './SaveViewModal.scss'

type Props = {
  name: string
}

export default function SaveViewModal({ name }: Props) {
  const handleClose = () => { ModalSignal.value = undefined }
  const handleShowView = () => {
    PanelSignal.title.value = 'Vues'
    PanelSignal.content.value = <ViewPanel />
    handleClose()
  }

  return (
    <div className="view-saved-modal">
      <p className="info flex-column-center">
        <CheckIcon />
        <h3>{terms.Modals.ViewSaved.title(name)}</h3>
        <span dangerouslySetInnerHTML={{ __html: terms.Modals.ViewSaved.info }} />
      </p>
      <div className="flex-center actions">
        <Button
          size={ButtonSize.medium}
          style={ButtonStyle.light}
          text={terms.Modals.ViewSaved.showView}
          onClick={handleShowView}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.close}
          onClick={handleClose}
        />
      </div>
    </div>
  )
}
