import { MapDataSignal } from 'pages/home/panels/PanelsManager'
import { CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'
import LineLayer from './generic/LineLayer'

export default function CDVLayer({
  view, visibility, mapRef,
}: LayerProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.cdv,
    view,
    CHARTIS_SOURCES_IDS.cdv,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal

  return (
    <LineLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.cdv}
      layers={[MAPBOX_LAYER_IDS.cdvLine, MAPBOX_LAYER_IDS.cdvLabel]}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      color="#038f03"
    />
  )
}
