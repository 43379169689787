/* eslint-disable camelcase */
import { signal } from '@preact/signals-react'
import { deleteRequest, get, post } from '@osrdata/app_core/dist/requests'
import { FavoriteObject } from 'types'
import { catchable } from 'utils'
import { ToastLevel, ToastSignal } from 'components'

export const FavoritesSignal = {
  objects: signal<FavoriteObject[]>([]),
  views: signal([]),
  perimeters: signal([]),
}

export const getFavoritesObjets = async () => {
  const [error, response] = await catchable(
    () => get<FavoriteObject[]>('/dexcarto/users/favorite-objects/'),
    true,
  )

  if (error) return

  FavoritesSignal.objects.value = response
}

export const postFavoriteObject = async (favorite: Omit<FavoriteObject, 'id'>) => {
  const [error] = await catchable(() => post('/dexcarto/users/favorite-objects/', favorite), true)
  if (error) return
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: 'L\'objet a été ajouté aux favoris',
  }
  getFavoritesObjets()
}

export const deleteFavoriteObject = async (id: string) => {
  const [error] = await catchable(() => deleteRequest(`/dexcarto/users/favorite-objects/${id}`), true)
  if (error) return
  ToastSignal.value = {
    severity: ToastLevel.INFO,
    message: 'L\'objet a été retiré des favoris',
  }
  getFavoritesObjets()
}
