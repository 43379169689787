import { useState } from 'react'
import { Signal } from '@preact/signals-react'
import { ReactComponent as EyeBlueIcon } from 'assets/icons/eye-blue.svg'
import { ReactComponent as DeleteBlueIcon } from 'assets/icons/delete-blue.svg'
import { deleteFavoriteObject, FavoritesSignal } from 'services/favorites'
import { ConfirmModal, Loader, ModalSignal } from 'components'
import { Feature, FeatureCollection } from 'geojson'
import { get } from '@osrdata/app_core/dist/requests'
import { ContentType, FavoriteObject, LayerFeature } from 'types'
import { ContentSignals } from 'pages/home/contents/ContentsManager'
import { calculateGeometryViewport, CHARTIS_SOURCES_IDS, getIndexForSlug, LayersSignal, MAP_IDS_SLUGS } from 'services'
import { MapDataSignal } from '../PanelsManager'

export default function FavoriteObjects() {
  const [loading, setLoading] = useState<string>('')
  const favorites = FavoritesSignal.objects.value
  const { data: leftData } = ContentSignals.left
  const { data: rightData } = ContentSignals.right

  const isCentroid = (favorite: FavoriteObject) => (
    favorite.layer_slug === CHARTIS_SOURCES_IDS.bif || favorite.layer_slug === CHARTIS_SOURCES_IDS.station
  )

  const handleGetFeature = async (favorite: FavoriteObject) => {
    const centroid = isCentroid(favorite) ? '_centroid' : ''
    const featureCollection = await get<FeatureCollection>(
      `/chartis/v2/layer/${favorite.layer_slug}/search/full_rgi_track_geo${centroid}/`,
      {
        columns: `geom_rgi_track_sch_flat${centroid},geom_rgi_track_geo${centroid}`,
        id: favorite.object_id,
      },
    )

    return featureCollection.features[0]
  }

  const handleUpdateContent = (feature: Feature, data: Signal<ContentType>, keySuffix: string) => {
    const type = data?.value?.type
    if (type !== 'sch' && type !== 'geo') return

    const geoKey = type === 'sch' ? `geom_rgi_track_sch_flat${keySuffix}` : `geom_rgi_track_geo${keySuffix}`
    const vp = calculateGeometryViewport({
      coordinates: feature.properties[geoKey].coordinates,
      type: feature.properties[geoKey].type,
    }, data.value.vp)

    data.value = { ...data.value, vp: { ...data.value.vp, ...vp, transitionDuration: 500 } }
  }

  const handleLocateObject = (favorite: FavoriteObject) => async () => {
    if (loading) return
    setLoading(favorite.id)

    const centroid = isCentroid(favorite) ? '_centroid' : ''
    const feature = await handleGetFeature(favorite)
    handleUpdateContent(feature, leftData, centroid)
    handleUpdateContent(feature, rightData, centroid)

    // Highlight object on map(s)
    MapDataSignal.hoveredObjects.value = [{ properties: { id: favorite.object_id } }] as LayerFeature[]

    const indexes = Object.entries(MAP_IDS_SLUGS).map(
      ([id, slugs]) => (id === favorite.layer_slug ? slugs.map(slug => getIndexForSlug(slug)) : undefined),
    ).filter(Boolean)

    LayersSignal.value = indexes.flat()

    setLoading('')
  }

  const handleDelete = (id: string) => () => {
    const closeModal = () => { ModalSignal.value = undefined }
    ModalSignal.value = (
      <ConfirmModal
        title="Supprimer le favori ?"
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteFavoriteObject(id)
          closeModal()
        }}
      />
    )
  }

  return (
    <div className="objects-favorites favorites hide-scroll">
      {favorites.map(favorite => (
        <div key={favorite.id} className="favorite objects">
          <div className="flex-center head">
            <p><b>{favorite.object_libelle}</b></p>
            {loading === favorite.id
              ? <Loader variant="x-small" />
              : <EyeBlueIcon onClick={handleLocateObject(favorite)} />}
            <DeleteBlueIcon onClick={handleDelete(favorite.id)} />
          </div>
          <p className="lvp">
            <b>Ferrolocalisation : </b>
            {favorite.object_lvp || 'N/C'}
          </p>
          <div className="divider" />
        </div>
      ))}
    </div>
  )
}
