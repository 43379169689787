/* eslint-disable react/no-danger */
import { useState } from 'react'
import { post } from '@osrdata/app_core/dist/requests'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import terms from 'assets/terms'
import { getViews } from 'services'
import {
  Button, ButtonSize, ButtonStyle, ModalSignal, ToastLevel, ToastSignal,
  ViewSavedModal,
} from 'components'
import { TextInput } from 'components/inputs'

import './SaveViewModal.scss'

export default function SaveViewModal() {
  const [name, setName] = useState('')

  const handleSave = async () => {
    try {
      await post('/dexcarto/users/views', { name, content: window.location.href })
      await getViews()
      ModalSignal.value = <ViewSavedModal name={name} />

      ToastSignal.value = {
        severity: ToastLevel.SUCCESS,
        message: terms.Modals.SaveView.success,
      }
    } catch (error) {
      ToastSignal.value = {
        severity: ToastLevel.ERROR,
        message: terms.Modals.SaveView.error,
      }
    }
  }

  const handleClose = () => { ModalSignal.value = undefined }

  return (
    <div className="save-view-modal">
      <p className="info flex-column-center">
        <h3>{terms.Modals.SaveView.title}</h3>
        <span dangerouslySetInnerHTML={{ __html: terms.Modals.SaveView.info }} />
      </p>
      <TextInput label={terms.Modals.SaveView.label} onChange={setName} />
      <div className="flex-center actions">
        <Button
          size={ButtonSize.medium}
          style={ButtonStyle.light}
          text={terms.Common.abort}
          onClick={handleClose}
          icon={<CloseIcon />}
        />
        <Button
          size={ButtonSize.medium}
          text={terms.Common.save}
          onClick={handleSave}
          icon={<SaveIcon />}
          disabled={!name}
          isAsync
        />
      </div>
    </div>
  )
}
