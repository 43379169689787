/* eslint-disable max-len */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { signal } from '@preact/signals-core'
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg'
import { ReactComponent as MapIcon } from 'assets/icons/map.svg'
import { ReactComponent as TableIcon } from 'assets/icons/table.svg'
import { ContentType } from 'types'
import {
  LayersSignal, getContentForParam, parseViewportToURL, SCH_DEFAULT_VIEWPORT, urlEqualsToViewport, reprojectViewport,
} from 'services'
import { debounce } from 'lodash'
import { Resizer } from 'components'
import { Map } from './map'
import CollectionTable from './table/CollectionTable'

import './ContentsManager.scss'

/**
 * Controls left and right content displayed in ContentsManager
 */
export const ContentSignals = {
  left: {
    data: signal<ContentType>(),
    element: signal<JSX.Element>(),
  },
  right: {
    data: signal<ContentType>(),
    element: signal<JSX.Element>(),
  },
}

export default function ContentsManager() {
  const [params, setSearchParams] = useSearchParams()
  const managerRef = useRef<HTMLDivElement>(null)
  const [splitPercent, setSplitPercent] = useState(50)
  const leftParam = params.get('vl')
  const rightParam = params.get('vr')
  const layerParams = params.get('l')
  const { data: leftData, element: leftElement } = ContentSignals.left
  const { data: rightData, element: rightElement } = ContentSignals.right

  // Init signal data based on new params
  const handleUpdateData = (newParams: string, key: 'left' | 'right' = 'left') => {
    const { data } = ContentSignals[key]
    if (urlEqualsToViewport(newParams, data.value)) return
    const { type, vp } = getContentForParam(newParams)
    data.value = { type, vp }
  }

  // Init signals based on url params
  useEffect(() => {
    if (!leftParam && !rightParam) handleUpdateData(`sch,${parseViewportToURL(SCH_DEFAULT_VIEWPORT)}`, 'left')
    if (leftParam) handleUpdateData(leftParam, 'left')
    if (rightParam) handleUpdateData(rightParam, 'right')
    if (!layerParams) LayersSignal.value = []
    else LayersSignal.value = layerParams.split(',')
  }, [])

  // Update params based on current content data value
  // TODO : refactor to merge left / right logic
  const handleUpdateParams = useCallback(debounce(() => {
    if (leftData.value?.type) {
      params.set('vl', `${leftData.value.type},${parseViewportToURL(leftData.value.vp)}`)
    } else {
      leftElement.value = null
      params.delete('vl')
    }
    if (rightData.value?.type) {
      params.set('vr', `${rightData.value.type},${parseViewportToURL(rightData.value.vp)}`)
    } else {
      rightElement.value = null
      params.delete('vr')
    }
    setSearchParams(params)
  }, 500), [LayersSignal.value, setSearchParams])

  // Update element based on key and type
  const hanldeUpdateElement = (key: 'left' | 'right', type: 'geo' | 'sch' | 'table') => {
    const { data, element } = ContentSignals[key]
    element.value = type === 'table'
      ? <CollectionTable key={`${key}-table"`} />
      : <Map key={`${key}-map-${type}`} data={data} />
  }

  // Update vl / vr params when content signal data changes
  // Update element only if type changes (check current element key)
  useEffect(() => {
    const leftType = leftData.value?.type
    const rightType = rightData.value?.type
    if (leftType && !leftElement.value?.key?.includes(leftType)) hanldeUpdateElement('left', leftType)
    if (rightType && !rightElement.value?.key?.includes(rightType)) hanldeUpdateElement('right', rightType)
    handleUpdateParams()
  }, [leftData.value, rightData.value])

  // Update layers params when layers signal data changes
  useEffect(() => {
    if (LayersSignal.value?.length !== 0) params.set('l', LayersSignal.value.join(','))
    if (LayersSignal.value && LayersSignal.value.length === 0) params.delete('l')
    setSearchParams(params)
  }, [LayersSignal.value])

  // Toggle view content type (geo / sch)
  const handleToggle = (key: 'left' | 'right', newType: 'sch' | 'geo' | 'table') => async () => {
    const { vp, type } = ContentSignals[key].data.value
    const newVp = await reprojectViewport(vp, type, newType)
    ContentSignals[key].data.value = { type: newType, vp: newVp }
  }

  const getElement = (key: 'left' | 'right', element: JSX.Element, data: ContentType) => data && (
    <div className={`content ${key}`} style={{ flexBasis: key === 'left' ? `${splitPercent}%` : `${100 - splitPercent}%` }}>
      <div className="content-toggler flex-center">
        <GlobeIcon className={data.type === 'geo' ? 'active' : ''} onClick={handleToggle(key, 'geo')} />
        <MapIcon className={data.type === 'sch' ? 'active' : ''} onClick={handleToggle(key, 'sch')} />
        <TableIcon className={data.type === 'table' ? 'active' : ''} onClick={handleToggle(key, 'table')} />
      </div>
      {element}
    </div>
  )

  return (
    <div
      id="contents-manager"
      className={`contents-manager flex-center ${(!!leftElement.value && !!rightElement.value) ? ' truncate' : ''}`}
      ref={managerRef}
    >
      {leftElement.value && getElement('left', leftElement.value, leftData.value)}
      {rightElement.value && (<Resizer minWidth={250} setSplitRatio={setSplitPercent} containerRef={managerRef} />)}
      {rightElement.value && getElement('right', rightElement.value, rightData.value)}
    </div>
  )
}
