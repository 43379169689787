import terms from 'assets/terms'
import { TextInput } from 'components/inputs'
import './PerimeterFields.scss'

interface Props {
  name: string
  setName: (name: string) => void
  description: string
  setDescription: (description: string) => void
}

export default function PerimeterFields({ name, setName, description, setDescription }: Props) {
  return (
    <div className="perimeter-fields">
      <TextInput label={terms.Modals.SavePerimeter.nameLabel} bindedValue={name} onChange={setName} />
      <div className="description-input">
        <label htmlFor="description">{terms.Modals.SavePerimeter.descriptionLabel}</label>
        <textarea
          id="description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          rows={3}
        />
      </div>
    </div>
  )
}
