/* eslint-disable camelcase */
import { useState } from 'react'
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg'
import { Button, ButtonStyle, ConfirmModal, ModalSignal } from 'components'
import moment from 'moment'
import { CommentType } from 'types'
import { catchable, useAppSelector, useMountEffect } from 'utils'
import { post } from '@osrdata/app_core/dist/requests'
import { deleteComment } from 'services'

export default function Comment({ comment }: { comment: CommentType }) {
  const accountId = useAppSelector(({ user }) => user.account.id)
  const [loadingUser, setLoadingUser] = useState(true)
  const [displayName, setDisplayName] = useState('Nom Prénom')
  const [displayMenu, setDisplayMenu] = useState(false)

  useMountEffect(async () => {
    const [error, response] = await catchable<{displayName: string}[]>(
      () => post('/cerbere/users/simple', [comment.user_id]),
    )
    if (error) setDisplayName('N/C')
    if (response) setDisplayName(`${response[0].displayName}`)
    setLoadingUser(false)
  })

  const handleDelete = async () => {
    if (accountId !== comment.user_id) return
    const closeModal = () => { ModalSignal.value = undefined }
    ModalSignal.value = (
      <ConfirmModal
        title="Supprimer le commentaire ?"
        handleClose={closeModal}
        handleValidate={async () => {
          await deleteComment(comment.id)
          closeModal()
        }}
      />
    )
  }

  return (
    <div key={comment.id} className="comment">
      <div className="flex-center head">
        <p className={`user${loadingUser ? ' loading' : ''}`}>{displayName}</p>
        <p className="date">{moment(comment.creation_date).format('DD/MM/YYYY')}</p>
        {accountId === comment.user_id && (
        <Button
          text=""
          icon={<DotsIcon />}
          style={ButtonStyle.borderLess}
          onClick={() => setDisplayMenu(!displayMenu)}
        />
        )}
        <div className={`menu ${displayMenu ? 'display' : ''}`}>
          <Button
            text="Supprimer"
            style={ButtonStyle.borderLess}
            onClick={handleDelete}
          />
        </div>
      </div>
      <p>{comment.content}</p>
    </div>
  )
}
