import { useState } from 'react'
import terms from 'assets/terms'
import { useMountEffect } from 'utils'
import { getFavoritesObjets } from 'services/favorites'
import { Button, ButtonStyle } from 'components'
import FavoriteObjects from './Objects'

import './FavoritesPanel.scss'

type Tabs = 'objects' | 'views' | 'perimeters' | 'collections'

export default function FavoritesPanel() {
  useMountEffect(getFavoritesObjets)
  const tabs: Tabs[] = ['views', 'objects', 'collections', 'perimeters']
  const [selected, setSelected] = useState<Tabs>('objects')
  const handleSelectTab = (tab: Tabs) => () => setSelected(tab)

  return (
    <div className="favorites-panel panel">
      <div className="tabs flex-center">
        {tabs.map(tab => (
          <Button
            key={tab}
            className={selected === tab ? 'selected' : ''}
            text={terms.Panels.Favorites.tabs[tab]}
            style={ButtonStyle.borderLess}
            disabled={tab !== 'objects'} // TODO: remove when tabs are implemented
            onClick={handleSelectTab(tab)}
          />
        ))}
      </div>
      <div className="divider" />
      <div className="content">
        {selected === 'objects' && <FavoriteObjects />}
        {selected === 'views' && <div className="favorites">Views</div>}
        {selected === 'perimeters' && <div className="favorites">Perimeters</div>}
        {selected === 'collections' && <div className="favorites">Collections</div>}
      </div>
    </div>
  )
}
