import { useState } from 'react'
import { RequestManager } from '@osrdata/app_core/dist/requests'
import { catchable } from 'utils'
import terms from 'assets/terms'
import {
  Button, ButtonSize, ButtonStyle, TextInput, ToastLevel, ToastSignal,
} from 'components'

import './AccessPage.scss'

export default function AccessPage() {
  const [loading, setLoading] = useState(false)
  const rm = new RequestManager()
  const [group, setGroup] = useState('')
  const [reason, setReason] = useState('')

  const handleUserRequest = async () => {
    setLoading(true)
    const [error] = await catchable(
      () => rm.post('/dexcarto/users/request', { dex_group: group, reason }),
      true,
    )
    if (!error) ToastSignal.value = { message: terms.Pages.Access.form.success, severity: ToastLevel.SUCCESS }
    setLoading(false)
  }

  return (
    <div id="access">
      <div className="modal">
        <div className="header">
          <h2>{terms.Pages.Access.title}</h2>
          <p>{terms.Pages.Access.info}</p>
        </div>
        <TextInput
          label={terms.Pages.Access.form.group}
          bindedValue={group}
          onChange={setGroup}
        />
        <TextInput
          label={terms.Pages.Access.form.reason}
          bindedValue={reason}
          onChange={setReason}
        />
        <div className="actions flex-center">
          <Button
            size={ButtonSize.medium}
            style={ButtonStyle.primary}
            text={terms.Pages.Access.form.action}
            disabled={!group || !reason || loading}
            onClick={handleUserRequest}
            isAsync
          />
        </div>
      </div>
      <div className="background" />
    </div>
  )
}
