import { layerMenu } from 'assets/layers'
import terms from 'assets/terms'
import { MenuLayer } from 'types'
import {
  allIndexesSelected,
  getSlugsForIndexes,
  handleLayerMenuSelect,
  LayersSignal,
  toggleAllIndexes,
} from 'services/layers'
import { CheckInput, RadioInput, Accordion } from 'components'

import './LayerPanel.scss'

export default function LayerPanel() {
  const getInputType = (type: string, key: string, label: string, tooltip: string) => {
    const Component = type === 'radio' ? RadioInput : CheckInput
    const isChecked = getSlugsForIndexes(LayersSignal.value).includes(key)

    return (
      <>
        <Component
          label=""
          defaultValue={isChecked}
          onChange={() => handleLayerMenuSelect(key, type)}
        />
        <p className="label">
          {label}
          {tooltip && <span className="tooltip">{tooltip}</span>}
        </p>
      </>
    )
  }

  const getEntry = (entry: [string, string | MenuLayer], parentType = 'checkbox', parentLabels = null) => {
    const [key, value] = entry

    if (typeof value === 'string') return getInputType(parentType, key, value, parentLabels?.[key])

    const { type = 'checkbox', tooltip, ...subEntries } = value
    const categoryType = parentType || type
    const accordionTitleComponent = (type === 'checkbox' || type === 'radio-child')
      ? getInputType(categoryType, key, key, parentLabels?.[key])
      : key

    return (
      <Accordion title={accordionTitleComponent} defaultExpanded={false}>
        {Object.entries(subEntries).map(subEntry => (
          <div key={subEntry[0]}>{getEntry(subEntry, type, tooltip)}</div>
        ))}
      </Accordion>
    )
  }

  return (
    <div className="layer-panel panel">
      <div className="flex-center">
        <CheckInput
          label=""
          defaultValue={allIndexesSelected()}
          onChange={toggleAllIndexes}
        />
        <p className="label">
          {allIndexesSelected() ? terms.Common.unselectAll : terms.Common.selectAll}
        </p>
      </div>
      {Object.entries(layerMenu).map(item => (
        <div key={item[0]}>{getEntry(item)}</div>
      ))}
    </div>
  )
}
