import { useEffect, useState } from 'react'
import './Loader.scss'

type Props = {
  message?: string | undefined;
  variant?: 'normal' | 'small' | 'large' | 'x-small'
  standalone?: boolean
  progress?: number
}

export default function Loader({ message, variant = 'normal', standalone = false, progress = null }: Props) {
  const [progressValue, setProgressValue] = useState(progress)
  let interval = null

  useEffect(() => {
    interval = !interval && setInterval(() => {
      setProgressValue(prev => {
        if (prev >= progress) {
          clearInterval(interval)
          return prev
        }
        return prev + 0.01
      })
    }, 25)

    return () => clearInterval(interval)
  }, [progress])

  return (
    <div className={`loader ${variant}${standalone ? ' standalone' : ''}`}>
      <div className="spinner" />
      {message && <div className="message">{`${message}...`}</div>}
      {typeof progressValue === 'number' && (
        <div className="progress">{`${Math.round(progressValue * 100)}%`}</div>
      )}
    </div>
  )
}
