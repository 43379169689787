import { Source, Layer } from 'react-map-gl'
import { hoverColor } from 'utils'

interface Props {
  url: string
  sourceId: string
  layer: string
  hoveredObjectsIds: string[]
  opacity: number
  lineFilter: string
  visibility: boolean
  paint?: mapboxgl.AnyPaint
  layout?: mapboxgl.AnyLayout
}

/**
 * Generic layer displaying an icon and (eventually) a label
 * It handles hovered object text highlighting and opacity decrease when filtering on a line
 * Renders a single mapbox layer
 * @param {string} url - The url of the vector source
 * @param {string} sourceId - The id of the source
 * @param {string} layer - The id of the layer
 * @param {string[]} hoveredObjectsIds - The ids of the hovered objects
 * @param {number} opacity - The opacity of the layer
 * @param {string} lineFilter - The line filter
 * @param {boolean} visibility - The visibility of the layer
 * @param {mapboxgl.AnyPaint} paint - The paint properties of the layer
 * @param {mapboxgl.AnyLayout} layout - The layout properties of the layer
 * @return {JSX.Element} The picto layer
*/

export default function PictoLayer({
  url, sourceId, layer, hoveredObjectsIds, opacity, lineFilter, visibility, paint, layout,
}: Props) {
  return (
    <Source
      id={sourceId}
      type="vector"
      url={url}
    >
      <Layer
        type="symbol"
        id={layer}
        source-layer={sourceId}
        layout={{
          'text-font': ['Open Sans Regular'],
          'text-size': 13,
          'text-offset': [0, -2],
          'icon-allow-overlap': true,
          'icon-rotation-alignment': 'map',
          visibility: visibility ? 'visible' : 'none',
          ...layout,
        }}
        minzoom={12}
        paint={{
          'text-color': hoverColor('#000000', hoveredObjectsIds),
          'text-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'icon-opacity': ['case',
            ['in', lineFilter, ['get', 'lrs_ligne']], 1,
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 1,
            opacity],
          'text-halo-color': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], '#ff0000',
            'transparent'],
          'text-halo-width': ['case',
            ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 0.5,
            0.1],
          ...paint,
        }}
      />
    </Source>
  )
}
