import { ReactComponent as Warning } from 'assets/icons/warning.svg'
import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import './ConfirmModal.scss'

interface Props {
  title: string;
  handleClose: () => void;
  handleValidate: () => void;
}

export default function ConfirmModal(
  { title, handleClose, handleValidate }: Props,
) {
  return (
    <div className="modal-confirm">
      <div className="warning-icon">
        <Warning />
      </div>
      <h3>{title}</h3>
      <div className="actions">
        <Button text={terms.Common.cancel} onClick={handleClose} style={ButtonStyle.light} />
        <Button text={terms.Common.continue} onClick={handleValidate} isAsync />
      </div>
    </div>
  )
}
