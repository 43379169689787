import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { Collection } from 'types'
import { Accordion, Button, ButtonStyle } from 'components'

import './CollectionDetail.scss'

type Props = {
  collection: Collection
  onClose: () => void
}

export default function CollectionDetail({ collection, onClose }: Props) {
  const handleFavorite = () => {
    // to implement
  }

  return (
    <div className="collection-detail hide-scroll">
      <h3>
        <Button
          style={ButtonStyle.borderLess}
          text=""
          icon={<ArrowLeftIcon />}
          onClick={onClose}
        />
        <p>{collection.nom_collection_gep}</p>
        <Button
          style={ButtonStyle.borderLess}
          text=""
          icon={<FavoriteIcon />}
          onClick={handleFavorite}
          disabled
        />
      </h3>
      <div className="separator" />
      <Accordion title="IS de la collection" defaultExpanded>
        {collection.infos_is.map(is => (
          <div key={is.id} className="is-item">
            <p>
              <b>Nom de l’IS : </b>
              {is.libelle}
            </p>
            <p>
              <b>Type : </b>
              {is.type}
            </p>
            <p>
              <b>Technologie : </b>
              {is.poste_technologie_libelle}
            </p>
            <p>
              <b>Ferrolocalisation : </b>
              {is.ligne_id}
            </p>
            <div className="separator" />
          </div>
        ))}
      </Accordion>
    </div>
  )
}
