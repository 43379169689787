import { MapDataSignal } from 'pages/home/panels/PanelsManager'
import { CHARTIS_SOURCES_IDS, MAPBOX_LAYER_IDS } from 'services'
import { LayerWithAngleProps } from 'types'
import { useActivePerimeterParams, useMapURL } from 'utils'
import PictoLayer from './generic/PictoLayer'

export default function JDZLayer({
  view, visibility, mapRef, angle,
}: LayerWithAngleProps) {
  const perimeterParam = useActivePerimeterParams('geom_rgi_track_sch_flat__bpolygon')
  const url = useMapURL(
    CHARTIS_SOURCES_IDS.jdz,
    view,
    CHARTIS_SOURCES_IDS.jdz,
    mapRef,
    perimeterParam,
  )
  const hoveredObjectsIds = MapDataSignal.hoveredObjects.value?.map(o => o.properties?.id) || []
  const { opacity, lineFilter } = MapDataSignal

  return (
    <PictoLayer
      url={url}
      sourceId={CHARTIS_SOURCES_IDS.jdz}
      layer={MAPBOX_LAYER_IDS.jdz}
      hoveredObjectsIds={hoveredObjectsIds}
      opacity={opacity}
      lineFilter={lineFilter.value}
      visibility={visibility}
      layout={{
        'icon-image': ['case',
          ['in', ['get', 'id'], ['literal', hoveredObjectsIds]], 'jdz_red',
          'jdz_blue'],
        'icon-size': 0.3,
        'icon-rotate': ['coalesce', ['get', angle], 0],
      }}
    />
  )
}
